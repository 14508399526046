export default [
  {
    path: 'home',
    label: 'Home',
  },
  {
    path: 'services',
    label: 'About Us',
  },
  {
    path: 'subscribe',
    label: 'Contact',
  },
  {
    path: '',
    label: '',
  },
];
